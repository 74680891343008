import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import {Attachment} from '../../../constants/propTypesDefinitions';
import {createFetchAttachmentRemove} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RemoveAttachment extends React.PureComponent {
    static propTypes = {
        attachment: Attachment.isRequired,
        reloadAttachments: PropTypes.func.isRequired,
    };

    render() {
        const {attachment, reloadAttachments} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstrániť prílohu</Trans>}>
                    <Button size="small" type="danger" icon="delete"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reloadAttachments}
            title={<Trans>Odstránenie prílohy</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť prílohu?</Trans>,
                createFetchAttachmentRemove(attachment.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Prílohu sa nepodarilo odstrániť.`)}
        />;
    }
}

export default RemoveAttachment;